
import { computed, ref } from 'vue'
import { courseSeriesList } from '@/states/couseSeries'
import { courseSeriesTags } from '@/states/tags'
import { Tag } from 'momai'

export default {
  setup: () => {
    const search = ref('')
    const selectedTag = ref(-1)
    const courseSeriesListAfterMachtedTags = computed(() => {
      const all = courseSeriesList.value
      const t = selectedTag.value
      if (t === -1) {
        return all
      }
      return all.filter(({ tag }) => tag.split(',').some(tId => Number(tId) === selectedTag.value))
    })
    return {
      search,
      courseSeriesTags,
      courseSeriesListAfterMachtedTags,
      setSelectedTag: (tag: Tag) => {
        selectedTag.value = tag.id
        search.value = tag.tagName
      },
      doSearch: () => {
        if (search.value === '') {
          selectedTag.value = -1
        }
      }
    }
  }
}
